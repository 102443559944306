@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-BoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 700;
}
